import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Common from "./Common";
import Settings from "./Settings";
import User from "./User";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    user: User,
    common: Common,
  });

export default createRootReducer;
