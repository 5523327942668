import React from "react";
import ReactDOM from "react-dom";

import Boot from "./Boot";
import * as serviceWorker from "./registerServiceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

ReactDOM.render(<Boot />, document.getElementById("root"));
serviceWorker.unregister();
