import React from "react";
import { Menu } from "antd";
import { Link, useRouteMatch } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

import { sidebarMenu } from "../../routes/menu";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const user = useSelector(({ user }) => user);
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const match = useRouteMatch();

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <div className="gx-sidebar-menuarea">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              {Object.entries(sidebarMenu).map(([groupKey, groupItems]) => {
                return match.path === groupKey ? (
                  <React.Fragment key={groupKey}>
                    {Object.entries(groupItems).map(([itemKey, item]) => {
                      if (item.separator) {
                        return (
                          <Menu.Item key={itemKey} style={{ height: "12px" }}>
                            <div className="gx-border-bottom"></div>
                          </Menu.Item>
                        );
                      }

                      if (!item.hidden || user.isAdmin) {
                        return (
                          <Menu.Item key={itemKey}>
                            <Link to={item.url}>
                              <span className="gx-mr-2">{item.icon}</span>
                              <span className="gx-menu-item-label">
                              <IntlMessages id={item.label}/>
                            </span>
                            </Link>
                          </Menu.Item>
                        );
                      }})}
                  </React.Fragment>
                ) : null;
              })}
            </Menu>
          </CustomScrollbars>
        </div>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
