import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import LayoutContainer from "./LayoutContainer";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import Fatal from "../Error/Fatal";
import SignRecovery from "../SignRecovery";
import Blank from "../Error/Blank";
import SignConfirm from "../SignConfirm";

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

const App = () => {
  const config = window.appConfig || {};
  const user = useSelector(({ user }) => user);

  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
  const lastModePath = useSelector(({ common }) => common.lastModePath);

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
    }
  }, [themeType]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  let initUrl = "";
  if (config.confCrm) {
    initUrl = lastModePath || "/crm";
  }
  if (!config.confCrm && config.confAdmin) initUrl = "/admin";

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        onError={() => {}}
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          {/* Common paths for auth */}
          {config.confAuthorize && (
            <Route
              path={[
                "/user/password/recovery/:ticket/:answer",
                "/user/password/recovery",
              ]}
              component={SignRecovery}
            />
          )}
          {config.confAuthorize && (
            <Route path={["/confirm/email/:code"]} component={SignConfirm} />
          )}
          {config.confAuthorize && <Route path="/signin" component={SignIn} />}
          {config.confAuthorize && <Route path="/signup" component={SignUp} />}

          {(!config.confAuthorize || user.isAuthorized) && config.confCrm && (
            <Route path="/crm">
              <LayoutContainer />
            </Route>
          )}

          {/* Admin container, authorized only */}
          {config.confAdmin && user.isAdmin && (
            <Route path="/admin">
              <LayoutContainer wide />
            </Route>
          )}

          {/* Force Signin, Blank and Errors*/}
          {config.confAuthorize && user.isPending && <Blank />}
          {config.confAuthorize && !user.isPending && !user.isAuthorized && (
            <SignIn />
          )}
          {initUrl && (
            <Route path="/" exact>
              <Redirect to={initUrl} />
            </Route>
          )}
          <Route path="*">
            <Fatal code={404} />
          </Route>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
