import config from "./config";

let onTokenExchanged = new Event("onTokenExchanged");

class apostolIdToken {
  constructor() {
    this.pending = false;
  }

  init = () => {
    this.load();
    this.parse();
    if (this.isPresent()) {
      this.interval = setInterval(
        this.checkExpiration,
        config.tokenCheckInterval
      );
      this.log("Is present");
      return this.tk.access_token;
    }
    this.log("Not present");
    return false;
  };

  get = () => {
    if (this.isPresent()) {
      return this.tk.access_token;
    }
    return false;
  };

  isPresent = () => {
    if (this.tk.access_token) {
      if (Date.now() < this.tk.valid_till) {
        return true;
      }
    }
    return false;
  };

  checkExpiration = () => {
    if (this.tk.access_token) {
      this.load();
      if (this.goingToExpire()) {
        this.exchange();
      }
    }
  };

  goingToExpire = () => {
    let dateTo = new Date(this.tk.valid_till);
    let dateNow = new Date();

    if (
      Date.now() >
      this.tk.valid_till - config.tokenExpireGapMin * 1000 * 60
    ) {
      this.log(`Going to expire? now:${dateNow} to:${dateTo} YES`);
      return true;
    }

    this.log(`Going to expire? now:${dateNow} to:${dateTo} NO`);
    return false;
  };

  parse = () => {
    const params = new URLSearchParams(document.location.hash.substr(1));
    if (params.get("access_token")) {
      this.set(
        params.get("access_token"),
        decodeURIComponent(params.get("refresh_token")),
        params.get("expires_in"),
        params.get("session")
      );
      if (config.debug) {
        console.log("http://localhost:3000/" + document.location.hash);
      }
      this.log(`PARSED from hash`);
      document.location.hash = "#";
    }
  };

  set = (access, refresh, expires, sessionId = false) => {
    let expires_in = parseInt(expires) || 0;
    let session = sessionId || this.tk.session;
    this.tk = {
      access_token: access && access !== "null" ? access : "",
      refresh_token: refresh && refresh !== "null" ? refresh : "",
      valid_till: Date.now() + expires_in * 1000,
      session,
    };
    this.save();
  };

  save = () =>
    localStorage.setItem(config.tokenStorageName, JSON.stringify(this.tk));

  load = () =>
    (this.tk = JSON.parse(localStorage.getItem(config.tokenStorageName)) || {});

  flush = () => localStorage.removeItem(config.tokenStorageName);

  exchange = async () => {
    this.log("EXCAHNGE asked");
    await this.fetch({
      subject_token: this.tk.access_token,
      subject_token_type: "urn:ietf:params:oauth:token-type:access_token",
      client_id: config.apiClientId,
      grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
    });
    document.dispatchEvent(onTokenExchanged);
  };

  fetch = async (rawBody) => {
    if (this.pending) {
      this.log("Pending...can't fetch again");
      return;
    }

    const body = JSON.stringify(rawBody);
    this.pending = true;

    this.log("FETCH body " + body);
    let response = await fetch(`${config.apiTokenUrl}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
      mode: "cors",
      cache: "no-store",
      credentials: "omit",
    });
    let json = await response.json();
    if (response.ok) {
      this.log("fetch SUCCESS");
      this.set(json["access_token"], json["refresh_token"], json["expires_in"]);
    } else {
      this.log("fetch FAILED: " + JSON.stringify(json));
      this.flush();
    }
    this.pending = false;
  };

  log = (message) => {
    if (config.debug) console.log(`%c Token: ${message}`, "color: purple");
  };
}

const accessToken = new apostolIdToken();
accessToken.init();

export default accessToken;
