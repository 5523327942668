import React from "react";
import { injectIntl } from "react-intl";
class Fatal extends React.Component {
  render() {
    const { code, intl } = this.props;
    const codes = {
      404: {
        code: "404",
        description: intl.formatMessage({ id: "extraPages.404Msg" }),
      },
    };

    let codeText = "";
    let descriptionText = "";

    if (this.props.code) {
      if (codes[code]) {
        codeText = codes[code].code;
        descriptionText = codes[code].description;
      }
    }

    return (
      <div className="gx-page-error-container">
        <div className="gx-page-error-content">
          <div className="gx-error-code">{this.props.codeText || codeText}</div>
          <h2>{this.props.descriptionText || descriptionText}</h2>
        </div>
      </div>
    );
  }
}
export default injectIntl(Fatal);
