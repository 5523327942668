import React from "react";
import { Button, Form, Input, Alert } from "antd";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";

import CircularProgress from "../components/CircularProgress";
import Context from "../Context";
import token from "./../api/token";
import Flex from "../util/Flex";

class SignIn extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      pending: false,
    };
  }

  config = window.appConfig || {};

  m = (key) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: "signin." + key });
  };
  singin = async (data) => {
    this.setState({ pending: true, error: false });
    let result = await this.context.api.q(
      "/oauth2/token",
      {
        grant_type: "password",
        username: data.identity,
        password: data.password,
      },
      { exact: true },
      false,
      true
    );
    if (result.access_token) {
      token.set(result.access_token, null, result.expires_in, result.session);
      document.location.href = "/";
    } else {
      if (result.hasOwnProperty("error")) {
        this.setState({ error: result.error_description });
      }
      this.setState({ pending: false });
    }
  };

  g = () => {
    let googleOAuth2Endpoint = new URL(
      "https://accounts.google.com/o/oauth2/v2/auth"
    );
    let params = {
      client_id: window.appConfig.googleClientId,
      redirect_uri: window.appConfig.apiDomain + "/oauth2/code/google",
      scope: "openid profile email",
      response_type: "code",
      access_type: "online",
      include_granted_scopes: true,
      prompt: "select_account",
      state: window.appConfig.state || "SignIn",
    };

    for (let k in params)
      googleOAuth2Endpoint.searchParams.append(k, params[k]);

    document.location.href = googleOAuth2Endpoint;
  };

  render() {
    const { pending, error } = this.state;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={"/assets/images/sbg.png"} alt="DEBTMaster" />
              </div>
              <div className="gx-app-logo-wid">
                <Helmet>
                  <title>{this.m("signin")}</title>
                </Helmet>
                <h1>{this.m("signin")}</h1>
                <p>{this.m("text")}</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src="/assets/images/logo.png" />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                name="basic"
                onFinish={this.singin}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: this.m("identity.invalid"),
                    },
                  ]}
                  name="identity"
                >
                  <Input placeholder={this.m("identity")} />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: this.m("password.invalid") },
                  ]}
                  name="password"
                >
                  <Input type="password" placeholder={this.m("password")} />
                </Form.Item>

                <Form.Item>
                  <Link to="/signup">{this.m("signup")}</Link>
                  <Link
                    className="gx-ml-3 gx-login-form-forgot"
                    to="/user/password/recovery"
                  >
                    {this.m("forgot")}
                  </Link>
                </Form.Item>
                {error && <Alert message={error} type="error" />}
                <Form.Item>
                  <Button type="primary" className="gx-mb-2" htmlType="submit">
                    {this.m("signin")}
                  </Button>
                  {this.config.googleAuthorize ? (
                  <Button className="gx-mb-2" onClick={this.g}>
                    <Flex>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z"
                          fill="#EB4335"
                        ></path>
                      </svg>
                      <div className="gx-ml-2">{this.m("google")}</div>
                    </Flex>
                  </Button>
                  ) : (
                    <Button className="gx-mb-2">
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
            {pending && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SignIn);
