import config from "./config";

class apostolCredentialsToken {
  log(m) {
    if (config.debug) {
      console.log(`CredentialsToken: ${m}`);
    }
  }
  async init() {
    const accessToken = this.getAccess();
    if (!accessToken || this.expired(accessToken)) {
      this.log("Init - new req");
      await this.new();
    } else {
      this.log("Init - OK");
    }
    return this.check();
  }

  async new() {
    const body = {
      client_id: config.apiClientId, 
      grant_type: "client_credentials",
    };

    return await this.fetch(body);
  }
  async refresh() {
    const refreshToken = this.getRefresh();
    if (!refreshToken) return false;
    const body = {
      client_id: config.apiClientId,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };

    return await this.fetch(body);
  }
  async fetch(body) {
    const response = await fetch(`${config.apiTokenUrl}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      mode: "cors",
      cache: "no-store",
      credentials: "omit",
    });
    const result = (await response.json()) || {};

    if (!response.ok) {
      this.flush();
      console.error(
        `CredentiialsToken error: ${response.status} ${response.statusText} ${result.error_description} `
      );
    }

    return this.store(result["access_token"], result["refresh_token"]);
  }
  store(accessToken, refreshToken) {
    let result = false;
    if (accessToken) {
      localStorage.setItem(config.credentialsTokenStorage, accessToken);
      result = true;
    }

    if (refreshToken) {
      localStorage.setItem(config.credentialsRefreshTokenStorage, refreshToken);
      result = true;
    }
    this.log(`Init - store ${result ? "OK" : "FAIL"}`);
    return result;
  }
  flush() {
    localStorage.removeItem(config.credentialsTokenStorage);
    localStorage.removeItem(config.credentialsRefreshTokenStorage);
    this.log(`Flush`);
  }

  getAccess() {
    return localStorage.getItem(config.credentialsTokenStorage);
  }

  getRefresh() {
    return localStorage.getItem(config.credentialsRefreshTokenStorage);
  }
  check() {
    const accessToken = this.getAccess();
    if (!accessToken) return false;
    if (this.expired(accessToken)) return false;
    if (!this.checkAudience(accessToken)) return false;
    return true;
  }
  checkAudience(accessToken) {
    if (!accessToken) return undefined;
    const jwtDecoded = this.parseJWT(accessToken);
    if (jwtDecoded) {
      return jwtDecoded.aud === config.apiClientId;
    }
    return false;
  }
  expired(accessToken) {
    if (accessToken) {
      const jwtDecoded = this.parseJWT(accessToken);
      if (jwtDecoded) {
        let now = new Date();
        let exp = new Date(jwtDecoded.exp * 1000);
        return now >= exp;
      }
    }
    return false;
  }
  parseJWT(token) {
    try {
      // Get Token Header
      const base64HeaderUrl = token.split(".")[0];
      const base64Header = base64HeaderUrl.replace("-", "+").replace("_", "/");
      const headerData = JSON.parse(window.atob(base64Header));

      // Get Token payload and date's
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const dataJWT = JSON.parse(window.atob(base64));

      dataJWT.header = headerData;

      return dataJWT;
    } catch (err) {
      return false;
    }
  }
}

const credentialsToken = new apostolCredentialsToken();
credentialsToken.init();

export default credentialsToken;
