import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import UserMenu from "./UserMenu";

const UserInfo = () => {
  const user = useSelector(({ user }) => user);
  const show = user.show || {};
  const { confAuthorize } = window.appConfig;

  if (!confAuthorize) return null;

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={<UserMenu />}
      trigger="click"
    >
      {show.picture && (
        <Avatar src={show.picture} className="gx-avatar gx-pointer" alt="" />
      )}
      {!show.picture && (
        <Avatar className="gx-avatar gx-pointer">{show.literal}</Avatar>
      )}
    </Popover>
  );
};

export default UserInfo;
