import api from "../../api/rest";
import getLocale from "../../util/getLocale";
import {switchLanguageSuccess} from "./Setting";

const config = window.appConfig || {};

const actions = {
  AUTH_CHECK: "AUTH_CHECK",
  AUTH_BEGIN: "AUTH_BEGIN",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",
  LOGOUT: "LOGOUT",

  authCheck: () => {
    return async (dispatch) => {
      if (!config.confAuthorize) {
        dispatch(actions.authError());
        return;
      }
      dispatch(actions.authBegin());
      let authResult = await api.q("/whoami");
      if (authResult) {
        let accessMap = {};
        let classes = await api.q(
          "/class",
          { fields: ["id", "code", "access"] },
          { toObject: true }
        );
        let typesMap = await api.q(
          "/type",
          { fields: ["id", "code", "name"] },
          { toObject: true, keyField: "code" }
        );
        for (let classId in classes) {
          let classItem = classes[classId];
          accessMap[classItem.code] = classItem.access;
        }

        if (authResult.company) {
          config.company = authResult.company;
        }

        if (authResult.locale) {
          const localeCode = authResult.locale.code;
          const locale = getLocale(localeCode);
          if (locale && localeCode) {
            dispatch(switchLanguageSuccess(locale));
          }
        }

        dispatch(actions.authSuccess(authResult, accessMap, typesMap));
      } else {
        dispatch(actions.authError());
      }
    };
  },

  authSuccess: (authResult, accessMap, typesMap) => {
    return { type: actions.AUTH_SUCCESS, authResult, accessMap, typesMap };
  },

  authBegin: () => {
    return { type: actions.AUTH_BEGIN };
  },

  authError: (errorMessage = "") => {
    return { type: actions.AUTH_ERROR, errorMessage };
  },

  logoutSuccess: () => ({
    type: actions.LOGOUT,
  }),

  logout: () => {
    return async (dispatch) => {
      await api.q("/sign/out");
      dispatch(actions.logoutSuccess());
    };
  },
};
export default actions;
