import {
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
} from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  NAV_STYLE,
  THEME_COLOR,
  THEME_TYPE,
  UPDATE_RTL_STATUS,
} from "../../constants/ThemeSetting";

import api from "../../api/rest";
const config = window.appConfig || {};

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({ type: WINDOW_WIDTH, width });
  };
}

export function setThemeType(themeType) {
  localStorage.setItem("dmcsThemeType", themeType);
  return (dispatch) => {
    dispatch({ type: THEME_TYPE, themeType });
  };
}

export function setThemeColor(themeColor) {
  return (dispatch) => {
    dispatch({ type: THEME_COLOR, themeColor });
  };
}

export function setDirectionRTL(rtlStatus) {
  return (dispatch) => {
    dispatch({ type: UPDATE_RTL_STATUS, rtlStatus });
  };
}

export function onNavStyleChange(navStyle) {
  return (dispatch) => {
    dispatch({ type: NAV_STYLE, navStyle });
  };
}

export function onLayoutTypeChange(layoutType) {
  return (dispatch) => {
    dispatch({ type: LAYOUT_TYPE, layoutType });
  };
}

export function switchLanguage(locale) {
  return async (dispatch) => {
    if (config.confCrm && config.confAuthorize) {
      await api.q("/session/set/locale", { id: null, code: locale.locale });
    }
    dispatch(switchLanguageSuccess(locale));
  };
}

export function switchLanguageSuccess(locale) {
  localStorage.setItem("dmcsDefaultLanguage", locale.locale);
  return (dispatch) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale,
    });
  };
}
