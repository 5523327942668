import React from "react";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Fatal from "../containers/Error/Fatal";

const routes = [
  //crm

  {
    path: "/crm/profile",
    component: lazy(() => import("./Profile/Profile")),
    exact: false,
    params: false,
  },
  {
    path: "/crm/search",
    component: lazy(() => import("./Search/Search")),
    exact: false,
    params: false,
  },

  {
    path: "/crm/company",
    component: lazy(() => import("./Company/Company")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/client",
    component: lazy(() => import("./Client/Client")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/customer",
    component: lazy(() => import("./Customer/Customer")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/realty",
    component: lazy(() => import("./Realty/Realty")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/account",
    component: lazy(() => import("./Account/Account")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/order",
    component: lazy(() => import("./Order/Order")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/transaction",
    component: lazy(() => import("./Transaction/Transaction")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/import",
    component: lazy(() => import("./Reports/Import")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/report/history",
    component: lazy(() => import("./Reports/ReportsHistory")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/reports",
    component: lazy(() => import("./Reports/Reports")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/notice",
    component: lazy(() => import("./Notice/Notice")),
    exact: false,
    params: false,
  },
  // {
  //   path: "/crm/templates",
  //   component: lazy(() => import("./Templates/Templates")),
  //   exact: false,
  //   params: true,
  // },
  {
    path: "/crm/debt",
    component: lazy(() => import("./Debt/DebtDetail")),
    exact: false,
    params: true,
  },
  // {
  //   path: "/crm/training",
  //   component: lazy(() => import("./Training/Training")),
  //   exact: true,
  //   params: false,
  // },
  {
    path: "/crm/trial",
    component: lazy(() => import("./Trial/Trial")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/execution",
    component: lazy(() => import("./Execution/Execution")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/archive",
    component: lazy(() => import("./Archive/Archive")),
    exact: true,
    params: false,
  },
  {
    path: "/crm",
    component: lazy(() => import("./Dashboard/Dashboard")),
    exact: true,
    params: false,
  },

  //admin
  {
    path: "/admin/groups",
    component: lazy(() => import("./Admin/Groups/Groups")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/users",
    component: lazy(() => import("./Admin/Users/Users")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/registry",
    component: lazy(() => import("./Admin/Registry/Registry")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/console",
    component: lazy(() => import("./Admin/Console/Console")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/resource",
    component: lazy(() => import("./Admin/Resource/Resource")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/workflow",
    component: lazy(() => import("./Admin/Workflow/Workflow")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/reference",
    component: lazy(() => import("./Admin/Reference/Reference")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/dblog",
    component: lazy(() => import("./Admin/DbLog/DbLog")),
    exact: false,
    params: false,
  },
  {
    path: "/admin/apilog",
    component: lazy(() => import("./Admin/ApiLog/ApiLog")),
    exact: false,
    params: false,
  },
  {
    path: "/admin",
    component: lazy(() => import("./Admin/Dashboard/AdminDashboard")),
    exact: true,
    params: false,
  },
];

const Routes = () => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={
            route.params
              ? [
                  `${route.path}/:param1/:param2/:param3`,
                  `${route.path}/:param1/:param2`,
                  `${route.path}/:param1`,
                  `${route.path}`,
                ]
              : `${route.path}`
          }
        >
          <route.component />
        </Route>
      ))}

      <Route path="*">
        <Fatal code={404} />
      </Route>
    </Switch>
  </div>
);

export default Routes;
