import {
  BellOutlined,
  CarOutlined,
  DatabaseOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route } from "react-router-dom";
import Flex from "../../util/Flex";
import { setThemeType } from "../../appRedux/actions/Setting";
import { changeModePath } from "../../appRedux/actions/Common";
import {
  THEME_TYPE_DARK,
  THEME_TYPE_SEMI_DARK,
} from "../../constants/ThemeSetting";

const AppsNavigation = () => {
  const config = window.appConfig || {};
  const user = useSelector(({ user }) => user);
  const intl = useIntl();

  const dispatch = useDispatch();
  const { themeType } = useSelector(({ settings }) => settings);
  const dark = themeType === "THEME_TYPE_DARK";

  const m = (key) => {
    return intl.formatMessage({ id: "icons." + key });
  };

  if (!config.confCrm) return null;
  return (
    <ul className="gx-app-nav" style={{ justifyContent: "space-between" }}>
      {user.raw.balance && (
        <li className="gx-app-balance">{user.raw.balance}</li>
      )}
      <Flex>
        <li>
          <Link to="/crm/notice/" title={m("notifications")}>
            <BellOutlined />
          </Link>
        </li>

        <li>
          <div
            title={m("dark")}
            className="gx-app-linkicon"
            onClick={() =>
              dispatch(
                setThemeType(dark ? THEME_TYPE_SEMI_DARK : THEME_TYPE_DARK)
              )
            }
          >
            <div className="icon icon-invert-color"></div>
          </div>
        </li>

        {user.isAdmin && config.confAdmin && (
          <li>
            <Link to="/admin/" title={m("admin")}>
              <ToolOutlined />
            </Link>
          </li>
        )}
      </Flex>
    </ul>
  );
};

export default AppsNavigation;
