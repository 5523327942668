import React from "react";

const SearchBox = ({ styleName, placeholder, onChange, onSubmit, value }) => {
  const onKeyDown = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      onSubmit();
    }
  };
  return (
    <div className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <input
          className="ant-input"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
        />
        <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" onClick={onSubmit} />
        </span>
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
