import React from "react";
import {
  CloudSyncOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BuildOutlined,
  ExportOutlined,
  ContainerOutlined,
  PartitionOutlined,
  ProfileOutlined,
  AuditOutlined,
  CloudUploadOutlined,
  SnippetsOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  SecurityScanOutlined,
  HddOutlined,
  FormOutlined, TeamOutlined, BankOutlined, HomeOutlined, IdcardOutlined,
} from "@ant-design/icons";

const horizontalMenu = {
  "/admin": {
    "sidebar.admin.journals": {
      "admin.apilog.apilog": {
        label: "admin.apilog.apilog",
        url: "/admin/apilog",
        icon: <CloudSyncOutlined />,
      },
      "admin.dblog.dblog": {
        label: "admin.dblog.dblog",
        url: "/admin/dblog",
        icon: <DatabaseOutlined />,
      },
    },
    "sidebar.admin.groups": {
      "admin.groups.groups": {
        label: "admin.groups.groups",
        url: "/admin/groups",
        icon: <UsergroupAddOutlined />,
      },
      "admin.users.users": {
        label: "admin.users.users",
        url: "/admin/users",
        icon: <UserOutlined />,
      },
    },
    "sidebar.admin.tools": {
      "admin.registry.registry": {
        label: "admin.registry.registry",
        url: "/admin/registry",
        icon: <BuildOutlined />,
      },
      "admin.console.console": {
        label: "admin.console.console",
        url: "/admin/console",
        icon: <ExportOutlined />,
      },
      "admin.resource.resource": {
        label: "admin.resource.resource",
        url: "/admin/resource",
        icon: <ContainerOutlined />,
      },
    },
    "sidebar.admin.settings": {
      "admin.workflow.workflow": {
        label: "admin.workflow.workflow",
        url: "/admin/workflow",
        icon: <PartitionOutlined />,
      },
      "admin.reference.reference": {
        label: "admin.reference.reference",
        url: "/admin/reference",
        icon: <ProfileOutlined />,
      },
    },
  },
};

const sidebarMenu = {
  "/crm": {
    "sidebar.crm.company": {
      label: "sidebar.crm.company",
      url: "/crm/company",
      hidden: true,
      icon: <BankOutlined />,
    },
    "sidebar.crm.client": {
      label: "sidebar.crm.client",
      url: "/crm/client",
      hidden: true,
      icon: <UserOutlined />,
    },
    "sidebar.crm.realty": {
      label: "sidebar.crm.realty",
      url: "/crm/realty",
      hidden: false,
      icon: <HomeOutlined />,
    },
    "sidebar.crm.customer": {
      label: "sidebar.crm.customer",
      url: "/crm/customer",
      hidden: false,
      icon: <IdcardOutlined />,
    },
    "sidebar.crm.account": {
      label: "sidebar.crm.account",
      url: "/crm/account",
      hidden: false,
      icon: <SnippetsOutlined />,
    },
    "sidebar.hr.1": {
      separator: true,
    },
    // "sidebar.crm.debt.new": {
    //   label: "sidebar.crm.debt.new",
    //   url: "/crm/newdebt/",
    //   hidden: false,
    //   icon: <FieldTimeOutlined />,
    // },
    // "sidebar.crm.debt.training": {
    //   label: "sidebar.crm.debt.training",
    //   url: "/crm/training/",
    //   hidden: false,
    //   icon: <FileSyncOutlined />,
    // },
    "sidebar.crm.debt.trial": {
      label: "sidebar.crm.debt.trial",
      url: "/crm/trial/",
      hidden: false,
      icon: <FileProtectOutlined />,
    },
    "sidebar.crm.debt.execution": {
      label: "sidebar.crm.debt.execution",
      url: "/crm/execution/",
      hidden: false,
      icon: <SecurityScanOutlined />,
    },
    "sidebar.crm.debt.archive": {
      label: "sidebar.crm.debt.archive",
      url: "/crm/archive/",
      hidden: false,
      icon: <HddOutlined />,
    },
    "sidebar.hr.2": {
      separator: true,
    },
    "sidebar.crm.reports": {
      label: "sidebar.crm.reports",
      url: "/crm/reports",
      hidden: false,
      icon: <AuditOutlined />,
    },
    "sidebar.crm.import": {
      label: "sidebar.crm.import",
      url: "/crm/import",
      hidden: false,
      icon: <CloudUploadOutlined />,
    },
    // "sidebar.crm.templates": {
    //   label: "sidebar.crm.templates",
    //   url: "/crm/templates",
    //   hidden: false,
    //   icon: <FormOutlined />,
    // },
  },

  "/admin": {
    "admin.apilog.apilog": {
      label: "admin.apilog.apilog",
      url: "/admin/apilog",
      icon: <CloudSyncOutlined />,
    },
    "admin.dblog.dblog": {
      label: "admin.dblog.dblog",
      url: "/admin/dblog",
      icon: <DatabaseOutlined />,
    },
    "admin.groups.groups": {
      label: "admin.groups.groups",
      url: "/admin/groups",
      icon: <UsergroupAddOutlined />,
    },
    "admin.users.users": {
      label: "admin.users.users",
      url: "/admin/users",
      icon: <UserOutlined />,
    },
    "admin.registry.registry": {
      label: "admin.registry.registry",
      url: "/admin/registry",
      icon: <BuildOutlined />,
    },
    "admin.console.console": {
      label: "admin.console.console",
      url: "/admin/console",
      icon: <ExportOutlined />,
    },
    "admin.resource.resource": {
      label: "admin.resource.resource",
      url: "/admin/resource",
      icon: <ContainerOutlined />,
    },
    "admin.workflow.workflow": {
      label: "admin.workflow.workflow",
      url: "/admin/workflow",
      icon: <PartitionOutlined />,
    },
    "admin.reference.reference": {
      label: "admin.reference.reference",
      url: "/admin/reference",
      icon: <ProfileOutlined />,
    },
  },
};

export { horizontalMenu, sidebarMenu };
