const appConfig = window.appConfig || {};
export default {
  debug: true,
  tokenStorageName: "DMAccessToken",
  credentialsTokenStorage: "DMCredentialToken",
  credentialsRefreshTokenStorage: "DMCredentialsRefreshToken",
  tokenExpireGapMin: 20,
  tokenCheckInterval: 1000 * 60 * 5,
  apiCheckInterval: 5000,
  clientSecret: "ztjABgDAdhpnqlAh5WHqvmxx",
  ...appConfig,
};
